.view-business-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
}

/*copy address div*/
.view-business-form-copy-addresss-button-div {
    width: 80%;
    text-align: center;
    position: relative;
    float: left;
    left: 10%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    height: 88px;
}

.view-business-form-copy-addresss-button {
    background-color: black;
    width: 100%;
    font-size: 12px;
    font-family: 'Courier New', Courier, monospace;
    color: wheat;
    text-align: left;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: pointer;
    border: hidden;
    position: relative;
    top: -2.8mm;
}

.view-business-form-copy-addresss-button:hover {
    color: #7CFC00;
}

.view-business-form-copy-addresss-button:active {
    border-style: solid;
    border-width: thin;
    border-color: #7CFC00;
}

.view-business-form-copy-addresss-button-div .copy-address-msg-span {
    visibility: hidden;
    width: 100%;
    height: 10px;

    /* Position the tooltip */
    position: relative;
    float: left;

    border: none;
    color: black;
    font-size: small;
    text-shadow: whitesmoke 1px 1px 5px;
    cursor: default ;
    font-style: normal;
    text-align: center;

}

.view-business-form-copy-addresss-button-div:hover .copy-address-msg-span {
    visibility: visible;
}

/*form control phone input field*/
.view-business-form-control-phone-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-phone-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-phone-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control email input field*/
.view-business-form-control-email-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-email-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-email-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control facebook input field*/
.view-business-form-control-facebook-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-facebook-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-facebook-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control instagram input field*/
.view-business-form-control-instagram-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-instagram-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-instagram-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control twitter input field*/
.view-business-form-control-twitter-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-twitter-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-twitter-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control twitch input field*/
.view-business-form-control-twitch-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-twitch-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-twitch-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control snapchat input field*/
.view-business-form-control-snapchat-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-snapchat-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-snapchat-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control tiktok input field*/
.view-business-form-control-tiktok-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-tiktok-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-tiktok-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control linkedin input field*/
.view-business-form-control-linkedin-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-linkedin-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-linkedin-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control youtube input field*/
.view-business-form-control-youtube-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-youtube-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-youtube-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
    border-bottom: 1px solid #7CFC00;
}

/*form control input field*/
.view-business-form-control-input-field-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.view-business-form-control-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.view-business-form-control-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
} /*border-bottom: 1px solid #7CFC00;*/

/*icon-section-one-div'*/
.view-business-form-icon-section-one-div {
    width: 100%;
}

/*find business button*/
.view-business-form-find-business-link-button-div { 
    position: absolute;
    width: 40%;
    bottom: 5%;
    left: 3.5%;
    text-align: center;
}

.view-business-form-find-business-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 90%;
    font-size: 1.2em;

    position: relative;
    cursor: pointer;
}

.view-business-form-find-business-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

/*share business social card button*/
.view-business-form-share-business-link-button-div {
    position: absolute;
    width: 45%;
    bottom: 5%;
    right: 5.5%;
    text-align: center;
}

.view-business-form-share-business-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 95%;
    font-size: 1.2em;

    position: relative;
    cursor: pointer;
}

.view-business-form-share-business-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

/*media query*/
@media (max-width: 576px) {
    .view-business-form {margin-top: auto;}
}
