.home-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.home-form-retrun-home-message-div{
    color: black;
    text-align: center;
    font-size: small;
    position: absolute;
    top: 1%;
    width: 93%;
    font-weight: bold;

    text-shadow: tomato 1px 1px 4px;
}

.home-form-image-div {
    background-image: url(HomePageImage.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    opacity: .8;

    border-style: hidden;
    border-color: wheat;
    border-left-color: #7CFC00;
    border-bottom-color: #7CFC00;
    border-width: thick;

    width: 25%;
    height: 200px;
    position: relative;
    /*left: 34%;
    top: 4%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px;
}

.home-form-react-player-div {
    background-color: yellow;
    opacity: .8;
    width: 100%;
    height: 200px;
    position: relative;
    top: 20%;
    margin: 0 auto;    
}

.home-form-add-business-link-button-div {
    position: absolute;

    width: 95%;
    left: 4.5%;
    bottom: 28%;
}

.home-form-add-business-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 2em;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.home-form-add-business-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.home-form-find-business-link-button-div {
    position: absolute;

    width: 95%;
    left: 4.5%;
    bottom: 19%;
}

.home-form-find-business-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 2em;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.home-form-find-business-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.home-form-update-business-link-button-div {
    position: absolute;

    width: 95%;
    left: 4.5%;
    bottom: 10%;
}

.home-form-update-business-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 2em;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.home-form-update-business-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.home-form-update-profile-link-button-div {
    position: absolute;
    width: 50%;
    bottom: 0%;
    left: -7%;
}

.home-form-update-profile-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: tomato 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 1em;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.home-form-update-profile-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.home-form-logout-link-button-div {
    position: absolute;
    width: 50%;
    bottom: 0%;
    right: 0%;
}

.home-form-logout-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: tomato 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 1em;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;

    text-align: right;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.home-form-logout-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

@media (max-width: 576px) {
    .home-form {margin-top: auto;}
}