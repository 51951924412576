.update-business-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
}

.update-business-form-control-div {
    position: relative;
    width: 80%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-first-name-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-first-name-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-first-name-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-last-name-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-last-name-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-last-name-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-country-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-country-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-country-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-state-province-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-state-province-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-state-province-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-city-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-city-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-city-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-zip-code-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-zip-code-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-zip-code-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-business-form-control-facebook-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-facebook-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-facebook-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-instagram-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;      
}

.update-business-form-control-instagram-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-instagram-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-twitter-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;   
}

.update-business-form-control-twitter-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-twitter-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-linkedin-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-linkedin-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-linkedin-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-snapchat-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;      
}

.update-business-form-control-snapchat-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-snapchat-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-tiktok-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;   
}

.update-business-form-control-tiktok-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 14px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-tiktok-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-twitch-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-twitch-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-twitch-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-youtube-input-field-div {
    position: relative;
    width: 40%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-youtube-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-youtube-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-bitcoin-input-field-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;
}

.update-business-form-control-bitcoin-input-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-bitcoin-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-ethereum-input-field-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;      
}

.update-business-form-control-ethereum-input-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-ethereum-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-control-xrp-input-field-div {
    position: relative;
    width: 27.166666667%;
    height: 5%;
    float: left;
    left: 10%;   
}

.update-business-form-control-xrp-input-field {
    background-color: black;
    width: 95%;
    border: none;
    font-size: 15px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
}

.update-business-form-control-xrp-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
    font-size: x-small;
}

.update-business-form-update-business-button-div {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 2%;
    left: 0%;
}

.update-business-form-update-business-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 80%;
    font-size: 1.5em;

    position: relative;
    cursor: pointer;
}

.update-business-form-update-business-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

@media (max-width: 576px) {
    .update-business-form {margin-top: auto;}
}


