.choose-business-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.choose-business-form-radio-button-div {
    background-color: khaki;
    font-weight: bold;
    width: 100%;
    height: 15%;
    position: relative;



    color: tomato;    
    text-shadow: black 1px 1px 1px;
    border-bottom: 1px solid black;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

}

.choose-business-form-radio-button {
    background-color: rgb(190, 31, 190);
    font-weight: bold;


    color: rgb(127, 32, 190);    
    text-shadow: black 1px 1px 1px;
    border-bottom: 1px solid black;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

}

.choose-business-form-ul {
    position: relative;
    width: 50%;
    left: 14%;
    overflow-y: auto;
}

.choose-business-form-li-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 100%;
    font-size: 1em;

    /*round edges*/
    border-radius: 5px;
    padding: 5px;

    cursor: pointer;

}

.choose-business-form-li-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.choose-business-form-error-message-div{
    position: relative;
    width: 100%;
}

.choose-business-form-error-message {
    color: tomato;
    text-shadow: black 0px 0px 7px;
    font-weight: bold;
    text-align: center;   
    font-size: 1em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.choose-business-form-error-message:hover {
    text-shadow: #7CFC00 1px 1px 4px;
}

@media (max-width: 576px) {
    .choose-business-form {margin-top: auto;}
}