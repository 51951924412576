.share-social-card-form-div {
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;   
}

.share-social-card-form-button-section-div {
    width: 100%;
    
}

.share-social-card-form-button-div {
    width: 25%;
    text-align: center;
    position: relative;
    float: left;
}

/*form control input field*/
.share-social-card-form-input-field-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
}

.share-social-card-form-input-field {
    background-color: black;
    width: 100%;
    border: none;
    font-size: 16px;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;
    cursor: auto;

}

.share-social-card-form-input-field::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 4px;
} /*border-bottom: 1px solid #7CFC00;*/