.social-card-heading-link-button {
    
    background-image: 
    linear-gradient(to right, black, black, #7CFC00, black, black);
  
    background-color: black;
    border-color: black;
    font-weight: bold;
    width: 100%;
    font-size: 2em;
    margin: 5px;
  
    /*round edges*/
    border-radius: 5px;
    padding: 5px;
  
    cursor: pointer;
    position: relative;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    animation-name: example;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;

    font-variant-caps:small-caps;
  }

.social-card-heading-link-button:hover {
    text-shadow: wheat 1px 1px 4px;
  }

/*phone icon button disabled*/
.phone-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.phone-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.phone-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.phone-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*phone icon hover message*/
.phone-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.phone-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.phone-icon-link-button-disabled-div:hover .phone-icon-link-button-disabled-message-span {
    visibility: visible;
}

/* bitcoin icon button disabled*/
.bitcoin-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.bitcoin-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.bitcoin-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.bitcoin-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*bitcoin icon hover message*/
.bitcoin-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.bitcoin-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.bitcoin-icon-link-button-disabled-div:hover .bitcoin-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*web site icon button disabled*/
.http-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.http-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.http-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.http-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*web site icon hover message*/
.http-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.http-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.http-icon-link-button-disabled-div:hover .http-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*linkedin icon button disabled*/
.linkedin-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.linkedin-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;
    left: 32%;

    opacity: 0.3;
}

.linkedin-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.linkedin-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*linkedin icon hover message*/
.linkedin-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.linkedin-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.linkedin-icon-link-button-disabled-div:hover .linkedin-icon-link-button-disabled-message-span {
    visibility: visible;
}

/* youtube icon button disabled*/
.youtube-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.youtube-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.youtube-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.youtube-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*youtube icon hover message*/
.youtube-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.youtube-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.youtube-icon-link-button-disabled-div:hover .youtube-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*facebook icon button disabled*/
.facebook-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.facebook-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.facebook-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.facebook-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*facebook icon hover message*/
.facebook-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.facebook-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.facebook-icon-link-button-disabled-div:hover .facebook-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*instagram icon button disabled*/
.instagram-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.instagram-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;
    left: 32%;

    opacity: 0.3;
}

.instagram-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.instagram-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*instagram icon hover message*/
.instagram-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.instagram-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.instagram-icon-link-button-disabled-div:hover .instagram-icon-link-button-disabled-message-span {
    visibility: visible;
}

/* twitter icon button disabled*/
.twitter-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.twitter-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.twitter-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.twitter-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*twitter icon hover message*/
.twitter-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.twitter-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.twitter-icon-link-button-disabled-div:hover .twitter-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*twitch icon button disabled*/
.twitch-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.twitch-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.twitch-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.twitch-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*twitch icon hover message*/
.twitch-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.twitch-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.twitch-icon-link-button-disabled-div:hover .twitch-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*snapchat icon button disabled*/
.snapchat-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.snapchat-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;
    left: 32%;

    opacity: 0.3;
}

.snapchat-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.snapchat-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*snapchat icon hover message*/
.snapchat-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.snapchat-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.snapchat-icon-link-button-disabled-div:hover .snapchat-icon-link-button-disabled-message-span {
    visibility: visible;
}

/* tik tok icon button disabled*/
.tiktok-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.tiktok-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.tiktok-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.tiktok-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*tiktok icon hover message*/
.tiktok-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.tiktok-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.tiktok-icon-link-button-disabled-div:hover .tiktok-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*whatsapp icon button disabled*/
.whatsapp-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.whatsapp-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.whatsapp-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.whatsapp-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*twitch icon hover message*/
.whatsapp-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.whatsapp-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.whatsapp-icon-link-button-disabled-div:hover .whatsapp-icon-link-button-disabled-message-span {
    visibility: visible;
}

.share-social-card-link-button-div {
    position: absolute;
    width: 50%;
    bottom: 5%;
    left: 8%;
}

.share-social-card-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 85%;
    font-size: 1.2em;

    position: relative;
    cursor: pointer;
    left: 7%;
}

.share-social-card-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.share-social-card-icon-div {
    width: 100%; 
}

.share-social-card-icon {
    font-size: xx-small;
    color: tomato;
}

.share-social-card-icon-div:hover {
    color: #7CFC00;
}

/*get card button*/
.view-social-card-form-get-card-link-button-div { 
   
    position: absolute;
    width: 30%;
    bottom: 5%;
    text-align: center;
    right: 13%;
}

.view-social-card-form-get-card-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: wheat 1px 1px 4px;
    font-weight: bold;
    width: 98%;
    font-size: 1.2em;

    position: relative;
    cursor: pointer;
}

.view-social-card-form-get-card-link-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.social-card-footer-link-button {
    color: black;
  
    background-color: black;
    border-color: black;
    font-weight: bold;
    width: 100%;
    font-size: 2em;
    margin: 5px;
  
    /*round edges*/
    border-radius: 15px;
    padding: 15px;
  
    cursor: pointer;
    position: relative;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    animation-name: example;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;

    font-variant-caps:small-caps;
  }

.social-card-footer-link-button:hover {
    text-shadow: wheat 1px 1px 4px;
  }


/* bitcoin icon button disabled*/
.bitcoin-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.bitcoin-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.bitcoin-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.bitcoin-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*bitcoin icon hover message*/
.bitcoin-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.bitcoin-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.bitcoin-icon-link-button-disabled-div:hover .bitcoin-icon-link-button-disabled-message-span {
    visibility: visible;
}

/**********************************************************/
/*phone icon button disabled*/
.bitcoin2-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.bitcoin2-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;

    opacity: 0.3;
}

.bitcoin2-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.bitcoin2-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*phone icon hover message*/
.bitcoin2-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.bitcoin2-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.bitcoin2-icon-link-button-disabled-div:hover .bitcoin2-icon-link-button-disabled-message-span {
    visibility: visible;
}

/*ethereum icon button disabled*/
.ethereum-icon-link-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.ethereum-icon-link-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: left;
    left: 32%;

    opacity: 0.3;
}

.ethereum-icon-link-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.ethereum-icon-link-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*ethereum icon hover message*/
.ethereum-icon-link-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.ethereum-icon-link-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.ethereum-icon-link-button-disabled-div:hover .ethereum-icon-link-button-disabled-message-span {
    visibility: visible;
}

/* xrp icon button disabled*/
.xrp-icon-button-disabled-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.xrp-icon-button-disabled {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: wheat;
    position: relative;
    float: right;

    opacity: 0.3;
}

.xrp-icon-button-disabled:hover {
    color: tomato;
    cursor: pointer;
}

.xrp-icon-button-disabled:active {
    font-size: 15px;
    cursor: pointer;
}

/*xrp icon hover message*/
.xrp-icon-button-disabled-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.xrp-icon-button-disabled-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: wheat 1px 1px 5px;
    cursor: default;
}

.xrp-icon-button-disabled-div:hover .xrp-icon-button-disabled-message-span {
    visibility: visible;
}