.logo-div {
  width: 25%;
  position: relative;
  left: 37.5%;
}
.tableSearch {
  color: wheat;
  text-align: center;
  text-shadow: #7CFC00 1px 1px 4px;
}

.searchByColumn {
  color: wheat;
  text-align: center;
  text-shadow: #7CFC00 1px 1px 4px;
}

table {
  table-layout: fixed;
  font-size: xx-small;
  font-variant: small-caps;
  color: wheat;
  width: 100%;
  text-align: center;
  text-shadow: #7CFC00 1px 1px 4px;
}

thead {
  background-color: rgb(50, 50, 50);
  word-wrap: break-word;
  color: yellowgreen;
  text-shadow: black 1px 1px 1px;
}

.colum-filter-textbox::placeholder{
  color: black;
  font-size: xx-small;
  text-align: center;
}

tbody {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

tbody tr:nth-child(odd){
  background-color: rgb(50, 50, 50);
}

td {
  word-wrap: break-word;
}

.select-page {
  color: wheat;
  position: relative;
  left: 43%;
}

.show-rows {
  color: black;
  position: relative;
  left: 47%;
}

.navButton {
  color: black;
  position: relative;
  left: 44%;
}

.view-business:any-link {
  color: wheat;
  
}

.view-business:hover {
  color: tomato;
  
}

.view-business:active {
  color: #7CFC00;
  
}

.loading-data {
  color: #7CFC00;
  text-align: center;
}

.screen-size-message {
  color: #7CFC00;
  text-align: center;
}



/*tfoot {
  background-color: #7CFC00;
  color: black;
  font-weight: bold;
  word-wrap: break-word;
}*/