/*xrp icon button div*/
.view-business-form-xrp-icon-button-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center; 
}

.view-business-form-xrp-icon-button {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: #00aae4;
    text-shadow: yellowgreen 1px 1px 1px;
    position: relative;
    float: right;
}

.view-business-form-xrp-icon-button:hover {
    color: #7CFC00;
    cursor: pointer;
}

.view-business-form-xrp-icon-button:active {
    font-size: 15px;
    cursor: pointer;
}

/*xrp icon button message div*/
.view-business-form-xrp-icon-button-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.view-business-form-xrp-icon-button-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: whitesmoke 1px 1px 5px;
    cursor: default;
}

.view-business-form-xrp-icon-button-div:hover .view-business-form-xrp-icon-button-message-span {
    visibility: visible;
}