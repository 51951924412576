.error-page-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.error-page-form-slogan-input-field-div {
    position: relative;
    width: 100%;
    text-align: center;
}

.error-page-form-slogan-input-field {
    background-color: black;
    border: none;
    height: 1px;
    width: 85%;
    font-size: 1em;
    margin: 15px;
    
    /*round edges*/
    border-radius: 20px;
    padding: 20px;

    cursor: cell;

    word-wrap: break-word;

    text-align: center;
}

.error-page-form-slogan-input-field::placeholder {
    color: tomato;    
    font-variant: small-caps;
    font-size: x-small;
    text-shadow: wheat 1px 1px 4px;
}

.error-page-form-slogan-input-field:focus{
    outline: none;
}

.error-page-form-example-input-field-div {
    position: relative;
    width: 100%;
    text-align: center;
}

.error-page-form-example-input-field {
    background-color: black;
    border: none;
    height: 1px;
    width: 85%;
    font-size: 1em;
    margin: 15px;
    
    /*round edges*/
    border-radius: 20px;
    padding: 20px;

    cursor: cell;

    word-wrap: break-word;

    text-align: center;
}

.error-page-form-example-input-field::placeholder {
    color: wheat;    
    font-variant: small-caps;
    font-size: x-small;
    text-shadow: #7CFC00 1px 1px 4px;
}

.error-page-form-example-input-field:focus{
    outline: none;
}

.error-page-form-input-field-div {
    position: relative;
    width: 100%;
    text-align: center;
}

.error-page-form-input-field {
    background-color: black;
    border: none;
    height: 1px;
    width: 85%;
    font-size: 14px;
    margin: 6px;
    
    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;

    word-wrap: break-word;

    text-align: center;
}

.error-page-form-input-field::placeholder {
    color: wheat;    
    font-variant: small-caps;
    font-size: x-small;
    text-shadow: tomato 1px 1px 4px;
}

.error-page-form-input-field:focus{
    outline: none;
}

.error-page-form-input-field2-div {
    position: relative;
    width: 100%;
    text-align: center;
}

.error-page-form-input-field2 {
    background-color: black;
    border: none;
    height: 1px;
    width: 85%;
    font-size: 14px;
    margin: 6px;
    
    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;

    word-wrap: break-word;

    text-align: center;
}

.error-page-form-input-field2::placeholder {
    color: tomato;    
    font-variant: small-caps;
    font-size: x-small;
    text-shadow: wheat 1px 1px 4px;
}

.error-page-form-input-field2:focus{
    outline: none;
}

.error-page-form-signup-link-button-div {
    position: absolute;
    width: 50%;
    left: 0%;
    text-align: center;
    bottom: 5%;
}

.error-page-form-signup-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: #7CFC00 1px 1px 4px;
    font-weight: bold;
    width: 95%;
    font-size: 2em;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.error-page-form-signup-link-button:hover {
    text-shadow: wheat 0px 0px 7px;
}

.error-page-form-login-link-button-div {
    position: absolute;
    width: 50%;
    right: 0%;
    text-align: center;
    bottom: 5%;     
}

.error-page-form-login-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: #7CFC00 1px 1px 4px;
    font-weight: bold;
    width: 95%;
    font-size: 2em;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.error-page-form-login-link-button:hover {
    text-shadow: wheat 0px 0px 7px;
}

@media (max-width: 576px) {
    .landing-page-form {display: flex;}
    .landing-page-form {flex-direction: column;}
    .landing-page-form {margin-top: auto;}

    .landing-page-form-signup-link-button-div {float: left;}

    .landing-page-form-login-link-button-div {float: left;}
}