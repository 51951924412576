/*instagram icon link button div*/
.view-business-form-instagram-icon-link-button-div {
    position: relative;
    width: 26.666666666%;
    height: 5%;
    float: left;
    left: 10%;
    text-align: center;
}

.view-business-form-instagram-icon-link-button {
    background-color: black;
    width: 35%;
    border: none;
    font-size: 25px;
    color: white;
    position: relative;
    float: left;
    left: 32%;
    background-image: 
        linear-gradient(to bottom left, #4c68d7, #cd486b, #fccc63);
        height: 27px;
        width: 37px;
            /*round edges*/
        border-radius: 15px;
        padding-right: 10px;
        text-align: center;
        align-self: center;
        align-items: center;
}

.view-business-form-instagram-icon-link-button:hover {
    color: #7CFC00;
    cursor: pointer;
}

.view-business-form-instagram-icon-link-button:active {
    font-size: 15px;
    cursor: pointer;
}

/*view instagram site hover message*/
.view-business-form-instagram-icon-link-button-message-span-div {
    position: relative;
    width: 100%;
    height: 5%;
    float: left;
    text-align: center;
}

.view-business-form-instagram-icon-link-button-message-span {
    background-color: black;
    border: none;
    visibility: hidden;
    color: black;
    font-size: small;
    text-shadow: #ffffff 1px 1px 5px;
    cursor: default ;
}

.view-business-form-instagram-icon-link-button-div:hover .view-business-form-instagram-icon-link-button-message-span {
    visibility: visible;
}


