body{
  background-color: black;
}

@keyframes example {
  from {text-shadow: wheat 0px 0px 7px;}
  to {text-shadow: wheat 1px 1px 4px;}
}

.app-logo-link-button-to-home {
    color: black;
  
    background-color: black;
    border-color: black;
    font-weight: bold;
    width: 74%;
    font-size: 2.4em;
    margin: 5px;
  
    /*round edges*/
    border-radius: 5px;
    padding: 5px;
  
    cursor: pointer;
    position: relative;

    /*stops button text from exiting form right/lef when resizing*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    animation-name: example;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;
  }

.app-logo-link-button-to-home:hover {
    text-shadow: wheat 1px 1px 4px;
  }

.app-logo-link-button-to-home-copyright-icon-div {
  color: tomato;
  font-size: x-small;
  width: 3%;
  position: relative;
  float: left;
} 
 
  .error{
    background-color: ;
    color: tomato;
    position: relative;
    float: right;
    width: 2%;
    font-size: xx-small;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.firebase-errors {
  color: tomato;
  font-size: xx-small;
  text-align: center;
  font-weight: bold;

  /*stops button text from exiting form right/lef when resizing*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;    
}
