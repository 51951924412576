.update-profile-form {
    background-color: black;
    border-style: double;
    border-top-color: wheat;
    border-left-color: tomato;
    border-bottom-color: #7CFC00;
    border-right-color: wheat;
    border-width: thick;
  
    height: 616px;
    width: 378px;
    position: relative;
    margin: auto;
    margin-top: 2.5%;

    /*round edges*/
    border-radius: 25px;
    padding: 20px; 

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.update-profile-form-first-name-input-div {
    position: relative;
    width: 100%;
    left: 10%;

    visibility: hidden;
}

.update-profile-form-first-name-input {
    background-color: black;
    border: none;
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;

    height: 1px;
    width: 75%;
    font-size: 15px;
    margin: 5px;
    
    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
    position: relative;
    float: left;
}

.update-profile-form-first-name-input::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-profile-form-full-name-input-div {
    position: relative;
    width: 100%;
    left: 10%;
}

.update-profile-form-full-name-input {
    background-color: black;
    border: none;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;

    width: 75%;
    height: 1px;
    font-size: 15px;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
    position: relative;
    float: left;
}

.update-profile-form-full-name-input::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-profile-form-email-input-div {
    position: relative;
    width: 100%;
    left: 10%;
}

.update-profile-form-email-input {
    background-color: black;
    border: none;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;

    width: 75%;
    height: 1px;
    font-size: 15px;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
    position: relative;
    float: left;
}

.update-profile-form-email-input::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-profile-form-password-input-div {
    position: relative;
    width: 100%;
    left: 10%;
}

.update-profile-form-password-input {
    background-color: black;
    border: none;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;

    width: 75%;
    height: 1px;
    font-size: 15px;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
    position: relative;
    float: left;
}

.update-profile-form-password-input::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-profile-form-confirm-password-input-div {
    position: relative;
    width: 100%;
    left: 10%;
}

.update-profile-form-confirm-password-input {
    background-color: black;
    border: none;
    color: wheat;
    text-shadow: yellowgreen 1px 1px 1px;

    width: 75%;
    height: 1px;
    font-size: 15px;
    margin: 5px;  

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
    position: relative;
    float: left;
}

.update-profile-form-confirm-password-input::placeholder {
    color: wheat;    
    text-shadow: yellowgreen 1px 1px 1px;
    border-bottom: 1px solid #7CFC00;
}

.update-profile-form-mode-of-contact-div {
    background-color: black;
    font-weight: bold;
    position: relative;
    width: 62%;
    left: 8%;

    color: black;    
    text-shadow: black 1px 1px 1px;
    border-bottom: 1px solid black;

    /*stops button text from exiting form right/lef when resizing, doesn't work for bottom*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

    visibility: hidden;
}

.update-profile-form-phone-input-div {
    position: relative;
    width: 100%;
    text-align: center;

    visibility: hidden;
}

.update-profile-form-phone-input {
    background-color: black;
    border: none;

    width: 85%;
    height: 1px;
    font-size: 15px;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: cell;
}

.update-profile-form-phone-input::placeholder {
    color: black;    
}

.update-profile-form-update-button-div {
    position: absolute;
    width: 50%;
    left: 0%;
    text-align: center;
    bottom: 5%;
}

.update-profile-form-update-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: tomato 1px 1px 4px;
    font-weight: bold;
    width: 95%;
    font-size: 2em;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.update-profile-form-update-button:hover {
    text-shadow: #7CFC00 0px 0px 7px;
}

.update-profile-form-cancel-update-link-button-div {
    position: absolute;
    width: 50%;
    right: 0%;
    text-align: center;
    bottom: 5%;  
}

.update-profile-form-cancel-update-link-button {
    background-color: black;
    border-color: black;
    color: black;
    text-shadow: #7CFC00 1px 1px 4px;
    font-weight: bold;
    width: 95%;
    font-size: 1em;
    margin: 5px;

    /*round edges*/
    border-radius: 15px;
    padding: 15px;

    cursor: pointer;
}

.update-profile-form-cancel-update-link-button:hover {
    text-shadow: wheat 0px 0px 7px;
}

@media (max-width: 576px) {
    .update-profile-form {margin-top: auto;}
}